import { IMoosaDataService, IScoringTemplate } from '@discngine/moosa-models';

import {
  createVersionisedListSlice,
  DataProviderWithVersions,
} from '@discngine/moosa-store/createVersionisedListSlice';

import { GetListParams } from './types';

const SCORING_TEMPLATES_WITH_VERSIONS_SLICE_NAME =
  'scoringTemplatesWithVersions' as const;

export const scoringTemplatesWithVersionsSlice = createVersionisedListSlice<
  typeof SCORING_TEMPLATES_WITH_VERSIONS_SLICE_NAME,
  IScoringTemplate,
  GetListParams
>({
  sliceName: SCORING_TEMPLATES_WITH_VERSIONS_SLICE_NAME,
});

type ScoringTemplatesDataProvider = DataProviderWithVersions<
  IScoringTemplate,
  GetListParams
>;

export const getScoringTemplatesDataProvider = (
  dataService: IMoosaDataService
): ScoringTemplatesDataProvider => ({
  getList: (params) => dataService.getScoringTemplates(params ?? {}),
  createItem: (payload) => dataService.createScoringTemplate(payload),
  getItem: (payload) => dataService.getScoringTemplate(payload),
  updateItem: (payload) => dataService.updateScoringTemplate(payload._id, payload),
  deleteItem: dataService.deleteScoringTemplate,
  undeleteItem: dataService.undeleteScoringTemplate,
  getVersions: dataService.getScoringTemplateVersions,
});
