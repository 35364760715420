import { CloseOutlined, DownOutlined } from '@ant-design/icons';
import { useIsOverflow } from '@discngine/moosa-common';
import {
  ColumnId,
  FieldType,
  IColumnLabelMap,
  DTNodeType,
  IColumnMetaInfo,
} from '@discngine/moosa-models';
import { Button, Dropdown, Tooltip } from 'antd';
import React, { FC, useMemo, useRef } from 'react';

import { assertUnreachable, getColumnLabel } from '../../../utils';

import styles from './PropertyNodeEditPanel.module.less';

type EditorPanelHeaderProps = {
  columnId: ColumnId;
  nodeType: DTNodeType.Property | DTNodeType.StructureSearch | DTNodeType.Date;
  columns: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined;
  onSelect: (columnId: ColumnId) => void;
  onCancel: () => void;
};

export const EditorPanelHeader: FC<EditorPanelHeaderProps> = ({
  columnId,
  nodeType,
  columns,
  columnLabelMap,
  getMetaData,
  onSelect,
  onCancel,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const label = useMemo(
    () => getColumnLabel(columnId, columnLabelMap),
    [columnLabelMap, columnId]
  );

  const isOverflow = useIsOverflow(ref, [label]);

  const columnsList: { key: string; label: string }[] = useMemo(() => {
    return columns
      .filter((columnId) => {
        switch (nodeType) {
          case DTNodeType.Property:
            return (
              getMetaData(columnId)?.type === FieldType.Number ||
              getMetaData(columnId)?.type === FieldType.String
            );
          case DTNodeType.Date:
            return getMetaData(columnId)?.type === FieldType.Date;
          case DTNodeType.StructureSearch:
            return getMetaData(columnId)?.type === FieldType.Structure;
          default:
            assertUnreachable(nodeType);
        }

        return false; // redundant but necessary for eslint
      })
      .map((option) => ({
        key: option,
        label: getColumnLabel(option, columnLabelMap),
      }));
  }, [columnLabelMap, columns, getMetaData, nodeType]);

  return (
    <div className={styles.panelHeader}>
      <Dropdown
        menu={{
          items: columnsList,
          selectable: true,
          selectedKeys: [columnId],
          onSelect: (event) => onSelect(event.key),
        }}
        overlayClassName={styles.columnSelector}
        placement="bottomLeft"
        trigger={['click']}
      >
        <Tooltip placement="bottom" title={isOverflow ? label : undefined}>
          <div className={styles.titleContainer}>
            <div ref={ref} className={styles.title}>
              {label}
            </div>
            <DownOutlined style={{ color: '#898989', fontSize: '12px' }} />
          </div>
        </Tooltip>
      </Dropdown>

      <Button className={styles.panelCloseButton} type="text" onClick={onCancel}>
        <CloseOutlined />
      </Button>
    </div>
  );
};
