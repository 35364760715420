import { CloseOutlined } from '@ant-design/icons';
import {
  DiscreteHistogram,
  HistogramData,
  isDiscreteHistogramData,
  isNumericHistogramData,
  NumericHistogram,
} from '@discngine/moosa-histogram';
import {
  ColumnId,
  DatasetRowId,
  DecisionTreeChartNode,
  DecisionTreeNodeResult,
  FieldType,
  IColumnLabelMap,
  IColumnMetaInfo,
} from '@discngine/moosa-models';
import { Alert, Button, Radio, Select, Space } from 'antd';
import { CheckboxOptionType } from 'antd/lib';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { HISTOGRAM_WIDTH, HISTOGRAM_HEIGHT } from '../../constants';
import { getColumnLabel } from '../../utils';

import styles from './shared/PropertyNodeEditPanel.module.less';

export interface ChartNodeEditPanelProps {
  nodeData: DecisionTreeChartNode;
  getAllRows: () => Set<string>;
  getNodeResult: (node: DecisionTreeChartNode) => DecisionTreeNodeResult | undefined;
  getMetaData: (columnId: ColumnId) => IColumnMetaInfo | undefined;
  getHistogramData: (
    node: DecisionTreeChartNode,
    rowIds: Set<DatasetRowId>
  ) => HistogramData | null;
  columns: ColumnId[];
  columnLabelMap?: IColumnLabelMap;
  onNodeChange: (node: DecisionTreeChartNode) => void;
  onCancel: () => void;
}

const options: CheckboxOptionType[] = [
  { value: false, label: 'Incoming' },
  { value: true, label: 'All' },
];

const bannedFieldTypes = new Set([FieldType.Structure, FieldType.Date]);

export const ChartNodeEditPanel: FC<ChartNodeEditPanelProps> = ({
  nodeData,
  getAllRows,
  getNodeResult,
  getMetaData,
  getHistogramData,
  columns,
  columnLabelMap,
  onNodeChange,
  onCancel,
}) => {
  const [nodeColumnId, setNodeColumnId] = useState<string | null>(nodeData.columnId);
  const [showFullDataset, setShowFullDataset] = useState<boolean>(
    Boolean(nodeData.showFullDataset)
  );

  const updateNodeData = useCallback(
    (columnId: ColumnId) => {
      setNodeColumnId(columnId);
      onNodeChange({ ...nodeData, columnId, showFullDataset });
    },
    [nodeData, onNodeChange, showFullDataset]
  );

  const updateShowFullDataset = useCallback(
    (showFullDataset: boolean) => {
      setShowFullDataset(showFullDataset);
      onNodeChange({ ...nodeData, columnId: nodeColumnId, showFullDataset });
    },
    [nodeColumnId, nodeData, onNodeChange]
  );

  const histogramData = useMemo(() => {
    const rowIds = showFullDataset
      ? getAllRows()
      : getNodeResult(nodeData)?.inputDatasetRowIds;

    if (!rowIds) return null;

    return getHistogramData(
      { ...nodeData, columnId: nodeColumnId, showFullDataset },
      rowIds
    );
  }, [
    showFullDataset,
    getAllRows,
    getNodeResult,
    nodeData,
    getHistogramData,
    nodeColumnId,
  ]);

  const numericHistogramData = useMemo(() => {
    return histogramData && isNumericHistogramData(histogramData) ? histogramData : null;
  }, [histogramData]);

  const discreteHistogramData = useMemo(() => {
    return histogramData && isDiscreteHistogramData(histogramData) ? histogramData : null;
  }, [histogramData]);

  const columnOptions = useMemo(() => {
    return columns
      .filter((columnId) => {
        const metadata = getMetaData(columnId);

        return metadata && !bannedFieldTypes.has(metadata.type);
      })
      .map((option) => (
        <Select.Option key={option} value={option}>
          {getColumnLabel(option, columnLabelMap)}
        </Select.Option>
      ));
  }, [columnLabelMap, columns, getMetaData]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.panelHeader}>
        <Button className={styles.panelCloseButton} type="text" onClick={onCancel}>
          <CloseOutlined />
        </Button>
        <div className={styles.panelTitle}>
          {nodeColumnId ? getColumnLabel(nodeColumnId, columnLabelMap) : 'Chart node'}
        </div>
      </div>

      <div className={styles.conditionRow}>
        <Select
          filterOption={true}
          placeholder={'Select a column'}
          showSearch={true}
          value={nodeColumnId}
          onChange={updateNodeData}
        >
          {columnOptions}
        </Select>
      </div>

      {nodeColumnId === null && (
        <div className={styles.conditionRow}>
          <Alert
            message="Column is not specified"
            style={{ width: '100%' }}
            type="error"
          />
        </div>
      )}

      {nodeColumnId !== null && (
        <>
          {numericHistogramData && (
            <NumericHistogram
              data={numericHistogramData}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
            />
          )}

          {discreteHistogramData && (
            <DiscreteHistogram
              data={discreteHistogramData}
              height={HISTOGRAM_HEIGHT}
              width={HISTOGRAM_WIDTH}
            />
          )}

          <Space size={10} wrap={true}>
            <span>Dataset rows:</span>

            <Radio.Group
              buttonStyle="solid"
              options={options}
              optionType="button"
              value={showFullDataset}
              onChange={(event) => updateShowFullDataset(event.target.value)}
            />
          </Space>
        </>
      )}
    </div>
  );
};
